body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px !important;
    background-color: whitesmoke;
}

.block {
    padding: 1.5rem 0;
}

@media (max-width: 600px) {
    .block {
        padding: 1.5rem 1.5rem;
    }
}

.fComic {
    font-family: "Comic Sans MS", cursive, sans-serif;
}

.fBackClr {
    background: #e6e6e6 !important;
}

.requisite {
    color: black;
    padding: 0.5em 0.75em;
    display: block;
}

.requisite:hover {
    background: whitesmoke;
}
